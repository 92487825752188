<template>
    <div class=ppl v-if="loaded">
        <div class=pplwithphoto>
            <div class=block>
                <p class=Title>Lab Members</p>
                <br>
            </div>
            <ul class=grid>
                <li clas=box v-for="director in people.Director" :key="director.id">
                    <div>
                        <a :href=director.url><img class=icon :src=director.photo /></a>
                    </div>
                    <a class=name :href=director.url>{{ director.name }}</a>
                    <p class=position>Director</p>
                </li>
                <li class=box v-for="admin in people.Administrator" :key="admin.id">
                    <div>
                        <a :href=admin.url><img class=icon :src=admin.photo /></a>
                    </div>
                    <a class=name :href=admin.url>{{ admin.name }}</a>
                    <p class=position>Administrator</p>
                </li>
                <li class=box v-for="phds in people.PhdStudent" :key="phds.id">
                    <div>
                        <a :href=phds.url><img :src=phds.photo class=icon /></a>
                    </div>
                    <a class=name :href=phds.url>{{ phds.name }}</a>
                    <p class=position>PhD Student</p>
                </li>
                <li class=box v-for="ms in people.MSStudent" :key="ms.id">
                    <div>
                        <a :href=ms.url><img :src=ms.photo class=icon /></a>
                    </div>
                    <a class=name :href=ms.url>{{ ms.name }}</a>
                    <p class=position>MS Student</p>
                </li>
            </ul>
            <div class=block>
                <p class=Title>Affiliated Faculty</p>
                <br>
            </div>
            <ul class=grid>
                <li class=box v-for="faculty in people.AffillatedFaculty" :key="faculty.id">
                    <div>
                        <a :href=faculty.url><img :src=faculty.photo class=icon /> </a>
                    </div>
                    <a class=name :href=faculty.url> {{ faculty.name }}</a>
                </li>
            </ul>
        </div>
        <div class=Alumni>
            <div class=block>
                <span class=Title>Alumni</span>
                <br>
            </div>
            <div>
                <p class=subTitle>Research Scientists/Engineers</p>
                <ul class=list>
                    <li class=listitem v-for="researcher in people.ResearchScientists" :key="researcher.id">
                        <a class=listelement v-bind:href=researcher.url>{{ researcher.name }}, {{ researcher.year }}
                            {{ researcher.loc }}</a>
                    </li>
                </ul>
                <p class=subTitle>PhDs</p>
                <ul class=list>
                    <li class=listitem v-for="phd in people.PhDs" :key="phd.id">
                        <a class=listelement v-bind:href=phd.url>{{ phd.name }}, {{ phd.year }} {{ phd.loc }}</a>
                    </li>
                </ul>
                <p class=subTitle>Masters</p>
                <ul class=list>
                    <li class=listitem v-for="master in people.Masters" :key="master.id">
                        <a class=listelement v-bind:href=master.url>{{ master.name }}, {{ master.year }} {{ master.loc
                            }}</a>
                    </li>
                </ul>
                <p class=subTitle>Undergrads</p>
                <ul class=list>
                    <li class=listitem v-for="undergrad in people.Undergrads" :key="undergrad.id">
                        <a class=listelement v-bind:href=undergrad.url>{{ undergrad.name }}, {{ undergrad.year }}
                            {{ undergrad.loc }}</a>
                    </li>
                </ul>
                <p class=subTitle>Visiting Scholars</p>
                <ul class=list>
                    <li class=listitem v-for="visitor in people.VisitingScientist" :key="visitor.id">
                        <a class=listelement v-bind:href=visitor.url>{{ visitor.name }}, {{ visitor.year }}
                            {{ visitor.loc }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
import people from '@/assets/people/people.json';
const loaded = people.length !== 0;
</script>

<style scoped>
:root {
    --boarder-color: rgb(202, 201, 199);
    --text-color: rgb(0, 0, 0);
}

/* container */
.ppl {

    display: inline-block;
    width: 940px;

}

/* Titles */
.Title {
    letter-spacing: .05em;
    float: left;
    font-weight: bold;
    margin-top: -20px;
    margin-bottom: 4px;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 32px;
}

.block {
    margin-top: 5vh
}

/* ul for members with photo */

.grid {
    list-style-type: none;
    display: grid;
    grid-template-columns: 300px 300px 300px;
    text-align: center;
    row-gap: 0px;
    vertical-align: center;
    margin-top: 30px;
}

/* li */
.box {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    height: 200px;
    width: 300px;
    vertical-align: center;

}

/* photo */
.icon {
    border-radius: 50%;
    width: 150px;
    height: 150px;
}

/* name and position */
.name {
    letter-spacing: .05em;
    font-size: 18px;
    /* font-family:'Roboto', sans-serif; */
    text-decoration: none;
    padding-top: 5px;
    color: var(--text-color);
}

.name:hover {
    color: rgba(103, 103, 104, 0.842)
}

.position {
    letter-spacing: .05em;
    font-size: 16px;
    /* font-family:'Roboto', sans-serif; */
    margin-top: 4px;
    margin-bottom: 0px;
    padding-bottom: 1.5em;
}

/* Alumni List */
.Alumni {
    letter-spacing: .05em;
    display: flex;
    flex-direction: column;
    float: left;
    text-align: left;
}

/* degrees */
.subTitle {
    font-size: 20px;
    font-weight: bold;
    padding-top: 2vh;
}

/* ul */
.list {
    padding-top: 1vh;
}

/* li */
.listitem {
    margin-bottom: 1rem;
}

/* a */
.listelement {
    letter-spacing: .05em;
    font-size: 18px;
    text-decoration: none;
    color: var(--text-color);
    color: inherit;
}

.listelement:hover {
    color: rgba(103, 103, 104, 0.842)
}
</style>